<template>
    <div>
        <dmx-title :bc-items="bcItems">{{ $t('admin_dashboard_users_title') }}</dmx-title>
        <b-row align-v="center" class="mb-1">
            <b-col>
                {{ pagination.totalListCount }} users found
                <span class="ml-3">
                    Show: <b-link @click="setPerPage(10)" :style="((options.perPage == 10) ? 'font-weight: bold;' : ' ')">10</b-link> |
                    <b-link @click="setPerPage(20)" :style="((options.perPage == 20) ? 'font-weight: bold;' : ' ')">20</b-link> |
                    <b-link @click="setPerPage(100)" :style="((options.perPage == 100) ? 'font-weight: bold;' : ' ')">100</b-link>
                </span>
            </b-col>
            <b-col>
                <b-input v-model="searchField"
                         @input="searchWithText()"
                         :loading="searchFieldIsLoading"
                         :placeholder="$t('common_search')">
                </b-input>
            </b-col>
            <b-col align="right">
                <b-button variant="success" @click="newUser()">
                    <b-icon icon="plus"></b-icon>
                    {{ $t('common_create') }}
                </b-button>
            </b-col>
        </b-row>

        <b-table id="users-table"
                 thead-class="dmx-thead-block"
                 :busy="loading"
                 outlined
                 striped
                 bordered
                 hover
                 :items="userList"
                 :fields="headers"
                 @row-clicked="editUser">
                 <template #cell(firstName)="{ item }">
                        <span v-if="!item.firstName">
                            {{ $t('missing_firstName') }}
                        </span>
                        <span v-else>
                            {{ item.firstName }}
                        </span>
                    </template>
                    <template #cell(lastName)="{ item }">
                        <span v-if="!item.lastName">
                            {{ $t('missing_lastName') }}
                        </span>
                        <span v-else>
                            {{ item.lastName }}
                        </span>
                    </template>
        </b-table>

        <div>
            <b-pagination align="center" v-model="options.currentPage"
                          :total-rows="pagination.totalListCount"
                          :per-page="options.perPage"
                          aria-controls="devices-table">
            </b-pagination>
        </div>
    </div>
</template>

<style scoped>

</style>
<script>
    import accountService from '@/services/accountService';
    import router from '@/router';

    export default {
        name: 'users',
        components: {
        },
        data: () => ({
            loading: false,
            userList: [],
            searchFieldIsLoading: false,
            searchField: '',
            pagination: {
                totalListCount: 0
            },
            options: {
                perPage: 10,
                currentPage: 1,
            },
            timeoutId: 0,
            bcItems: [
                {
                    text: 'users',
                    href: '/users',
                    active: true,
                }
            ]

        }),
        computed: {
            headers() {
                return [
                    {
                        label: this.$t('common_id'),
                        sortable: true,
                        key: 'id',
                    },
                    {
                        label: this.$t('common_firstname'),
                        sortable: true,
                        key: 'firstName',
                    },
                    {
                        label: this.$t('common_lastname'),
                        sortable: true,
                        key: 'lastName',
                    },
                    {
                        label: this.$t('common_email'),
                        sortable: true,
                        key: 'email',
                    },
                    {
                        label: this.$t('common_phone'),
                        sortable: true,
                        key: 'phone',
                    },
                ]
            }
        },
        watch: {
            options: {
                handler() {
                    this.getUsers()
                },
                deep: true,
                immediate: true,
            },
        },
        methods: {
            editUser(e) {
                router.push({ name: 'editUser', params: { userId: e.id } })
            },
            newUser() {
                router.push({ name: 'registerUser' })
            },
            searchWithText() {
                this.searchFieldIsLoading = true;

                if (this.timeoutId > 0) {
                    clearTimeout(this.timeoutId);
                }
                this.timeoutId = window.setTimeout(() => {
                    this.getUsers();
                }, 600);
            },
            getUsers() {
                accountService.filterUserList({
                    Count: this.options.perPage,
                    Items: [],
                    //SortBy: this.options.sortBy[0],
                    SearchTerm: this.searchField,
                    //SortDesc: this.options.sortDesc[0],
                    Page: this.options.currentPage,
                })
                    .then((data) => {
                        this.userList = data.items;

                        this.searchFieldIsLoading = false;
                        this.options.currentPage = data.page
                        this.pagination.totalListCount = data.totalListCount

                        this.loading = false
                    })
                    .catch(function (error) {
                        alert(error);
                    });
            }
        }
    }
</script>